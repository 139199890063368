@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.Bun {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;

  @media #{map.get(v.$media, "desktopS")} {
    display: none;
  }
}

.Slice {
  border-radius: 3px;
  width: 20px;
  height: 3px;
  background-color: #152b81;
  margin: 4px 0;
  transition: 0.4s;

  &[data-open='true'] {
    background-color: #f00;
  }

  &.top {
    transform: initial;
    &[data-open='true'] {
      transform: rotate(-45deg) translate(-5px, 5px);
    }
  }

  &.middle {
    opacity: initial;
    &[data-open='true'] {
      opacity: 0;
    }
  }

  &.bottom {
    transform: initial;
    &[data-open='true'] {
      transform: rotate(45deg) translate(-5px, -5px);
    }
  }
}

.BurgerMenu {
  display: none;
  position: fixed;
  top: 40px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #fff;
  z-index: 3;
  padding: 0 20px 20px;
  overflow-y: auto;

  &[data-open='true'] {
    display: block;
  }
}

.BurgerMenuList {
  list-style: none;
  text-align: left;
  width: 100%;
  padding: 0;
}

.BurgerMenuItem {
  background: none;
  border: none;
  display: block;
  color: var(--waterBlue);
  padding: 10px 10px 10px 25px;
  font-weight: bold;
  font-family: RTLGraphik;
  font-size: 14px;
  text-decoration: none;
  line-height: 21px;
  text-align: left;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin: 0;

  &.level-one {
    border-bottom: none;
  }

  &.level-two {
    color: var(--lightGreen);
    border-bottom: none;
  
    svg {
      fill: var(--lightGreen);
    }
  }

  &[data-active=true] {
    background: #f1f7fc;
  }

  &[data-hide=true]{
    display: none
  }

  svg {
    height: 10px;
    fill: var(--waterBlue);
    padding-right: 10px;
  }

  &.rotateSvg {
    svg {
      padding: 0 0 0 7px;
      transform: rotate(180deg);
    }
  }
}

.BurgerSubMenu {
  display: none;

  > ul {
    padding: 0;
  }

  &[data-open='true'] {
    display: block;
  }
}

.BurgerMenuListItem {
  display: initial;
  list-style-type: none;

  &[data-hide='true'] {
    display: none;
  }
}
