@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.Overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	overflow: hidden;
	display: flex;
	justify-content: center;
	background: rgba(255, 255, 255, 0.5);
	z-index: 5;
	padding-top: 100px;

	@media #{map.get(v.$media, "tabletMax")} {
		padding-top: 0;
	}
}

.Content {
	max-width: 384px;
	padding: 58px;
	background-color: var(--white);
	border: 1px solid #eee;
	height: fit-content;

	@media #{map.get(v.$media, "tabletMax")} {
		max-width: 100%;
		height: 100%;
		padding: 26px;
	}
}
