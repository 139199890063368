@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.Container {
	display: none;
	background-color: var(--lightGreen);
	height: 48px;
	@media #{map.get(v.$media, "desktopS")} {
		display: block;
	}
}

.MenuItemsContainer {
	display: flex;
	margin: 0 auto;
	background-color: var(--lightGreen);
	height: 48px;
	max-width: 1240px;
	padding: 0 20px;
}

.MenuItem {
	position: relative;

	height: 48px;
	width: auto;
	display: flex;
	align-items: center;
	font-size: 19px;
	background-color: var(--lightGreen);
	padding: 0 18px;
	border: none;

	span {
		color: var(--white);
	}

	&:hover {
		cursor: pointer;
		background-color: var(--landGreen);
	}

	svg {
		width: 35px;
		fill: var(--white);
	}
}

.MenuItemHome {
	background-color: var(--landGreen);
	padding: 0 14px;
}

.DropDownMenu {
	display: flex;
	position: absolute;
	background-color: var(--landGreen);
	top: 48px;
	z-index: 7;
	display: none;
	color: var(--white);
	margin-left: -18px;
	font-size: 16px;
	min-width: 200px;

	&[data-show='true'] {
		display: block;
	}
}

.SubmenuItem {
	padding: 8px 19px;
	display: block;
	text-decoration: none;
	color: var(--white);
}
