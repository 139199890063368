@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.header {
	display: flex;
	padding: 12px 20px 3px;
	border-bottom: 1px solid #c4c4c4;
	text-align: center;
	margin: 0 auto;
	max-width: 1280px;
	background-color: var(--white);

	@media #{map.get(v.$media, "desktopSMax")} {
		position: sticky;
		top: 0;
		z-index: 4;
	}
}

.headerLink {
	cursor: pointer;
	margin: 0 auto;

	svg {
		height: 20px;
	}

	@media #{map.get(v.$media, "desktopS")} {
		margin: initial;

		svg {
			height: initial;
		}
	}
}
