@use "sass:map";
@use "styles/variables.module.scss" as v;

.AdContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0;

	@media #{map.get(v.$media, "tablet")} {
		margin: 0
	}
}

.top,
.skyscraper,
.rectangle,
.rectangleLarge {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.top {
	@media #{map.get(v.$media, "mobileS")} {
		width: 320px;
		height: 50px;
	}

	@media #{map.get(v.$media, "mobileM")} {
		height: 100px;
	}

	/* Specific breakpoint to accomodate certain adslot. Most likely not reused thoughout the app. */
	@media (min-width: 490px) {
		width: 468px;
	}

	@media #{map.get(v.$media, "tablet")} {
		width: 768px;
		height: 95px;
	}

	@media #{map.get(v.$media, "desktopS")} {
		width: 970px;
		height: 250px;
	}
}

.skyscraper {
	@media #{map.get(v.$media, "tablet")} {
		width: 120px;
		height: 600px;
	}

	@media #{map.get(v.$media, "desktopM")} {
		width: 300px;
	}
}

.rectangle {
	width: 320px;
	height: 250px;
}

.rectangleLarge {
	width: 320px;
	height: 600px;
}