.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CenteredContent {
  padding: 0 35px;
  text-align: center;
}

.IconContainer {
  -webkit-transform: rotate(340deg);
  -ms-transform: rotate(340deg);
  transform: rotate(340deg);
  > svg {
    height: 35px;
    fill: var(--waterBlue);

    animation: rotate 1.5s ease-out 1 forwards;
  }

  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    50% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    51% {
      -webkit-transform: rotate(1200deg);
      -moz-transform: rotate(1200deg);
      -ms-transform: rotate(1200deg);
      -o-transform: rotate(1200deg);
      transform: rotate(1200deg);
    }

    100% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}
