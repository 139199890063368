.TextButton {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  height: 30px;
  text-align: left;
}

.TextLink {
  --textColor: var(--lightGreen);
  --backgroundColor: var(--lightBlue);
  --textHoverColor: var(--waterBlue);
  font-size: 1.6rem;
  font-weight: 600;
  padding: 6px 0;
  text-decoration: none;
  color: var(--textColor);
  display: flex;
  align-items: baseline;

  svg {
    width: 6px;
    fill: var(--textColor);
    background: none;
    margin-right: 6px;
    flex-shrink: 0;
  }

  &:hover {
    font-weight: 500;
    background-color: var(--backgroundColor);
    color: var(--textHoverColor);

    svg {
      visibility: hidden;
    }
  }

  &.footer {
    --textColor: var(--white);
    --backgroundColor: none;
    --textHoverColor: var(--skyBlue);

    &:hover {
      svg {
        display: initial;
      }
    }
  }

  &.popup {
    --textColor: var(--waterBlue);
    --backgroundColor: none;
    height: 16px;

    margin: 0;
    text-decoration: underline;

    &:hover {
      margin: 0;
    }
  }

  &.warningsbar {
    --textColor: white;
    --backgroundColor: none;
    color: white;
    margin: 0;
    align-items: center;
    flex-direction: column;
    
    &:hover { 
      margin: 0;
    }
  }

  
}
