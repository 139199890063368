.SearchResultsContainer {
	position: absolute;
	width: 100%;
	z-index: 6;
	border: 1px solid var(--lightGrey);
	border-radius: 0 0 5px;
	background-color: var(--white);
}

.SearchResultsHeading {
	background-color: var(--waterBlue);
	padding: 10px 16px;
	color: var(--white);
}

.SearchResultsHeadingLight {
	background-color: var(--reallyLightBlue);
	padding: 10px 16px;
	color: var(--waterBlue);
}

.SearchResults {
	list-style: none;
	padding: 0;
	margin: 0;
}

.SearchResult {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 8px 5px 16px;
	color: var(--landGreen);

	a {
		width: 100%;
		text-align: left;
	}

	&:hover {
		background-color: var(--lightBlue);
	}

	span {
		font-size: 1.3rem;
		color: var(--darkGrey);
	}
}

.SearchResultButton {
	background: none;
	border: none;
	color: var(--landGreen);
	font-size: 1.5rem;
	text-align: left;
}

.SearchResultSelected {
	background-color: var(--lightBlue);
}

.FavoriteButtonActive {
	background-image: url(../../../public/icons/favorite-active.svg);
}

.FavoriteButtonInActive {
	background-image: url(../../../public/icons/favorite-inactive.svg);
}

.FavoriteButton {
	border: none;
	margin-left: auto;
	width: 18px;
	height: 18px;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: transparent;
}

.visuallyHidden {
	&:not(:focus):not(:active) {
		clip: rect(0 0 0 0);
		clip-path: inset(100%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}
}
