@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.searchBarContainer {
  position: relative;

  &[data-variant='label'],
  &[data-variant='navigation'] {
    display: none;
    @media #{map.get(v.$media, "desktopS")} {
      display: flex;
    }
  }

  &[data-variant='label'] {
    @media #{map.get(v.$media, "desktopS")} {
      flex-direction: column;
      align-items: center;
      background-color: var(--lightBlue);
      padding: 21px 28px 20px 16px;
      position: relative;
    }
  }
  &[data-variant='navigation'] {
    @media #{map.get(v.$media, "desktopS")} {
      justify-content: right;
      margin-left: auto;
    }
  }
}

.searchBarForm {
  display: grid;
  grid-template-columns: 1rf 1rf;

  &[data-variant='label'],
  &[data-variant='navigation'] {
    max-width: 600px;
    display: flex;
    align-items: center;
  }
}

.searchBarLabel {
  display: none;

  &[data-variant='label'],
  &[data-variant='navigation'] {
    font-weight: 600;
    font-size: 1.8rem;
    color: var(--waterBlue);
    margin-right: 10px;
    display: inline-block;
  }
}

.searchBarInputResults {
  position: relative;

  &[data-variant='label'],
  &[data-variant='navigation'] {
    width: 300px;
  }
}

.searchBarInput {
  font-size: 1.6rem;
  font-weight: 400;
  border: 1px solid var(--grey);
  border-radius: 5px;
  height: 40px;
  padding: 0 35px 0 16px;
  width: 100%;
  color: var(--waterBlue);
  background-color: var(--white);
  grid-column: 1/-1;
  grid-row: 1;

  &::placeholder {
    color: var(--darkGrey);
  }

  &::-webkit-search-cancel-button {
    display: none;
  }

  &[data-variant='label'],
  &[data-variant='navigation'] {
    background-color: var(--white);
    padding: 8px 0 12px 15px;
    padding: 12px 0 12px 15px;
    border: none;
    border-radius: 0px;
    font-size: 1.8rem;
  }
}

.searchBarIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  grid-column: 2;
  grid-row: 1;

  &[data-variant='default'] {
    position: absolute;
    right: 0px;
  }

  svg {
    fill: var(--waterBlue);
  }

  &[data-variant='label'],
  &[data-variant='navigation'] {
    background-color: var(--waterBlue);

    svg {
      fill: var(--white);
    }
  }
}
