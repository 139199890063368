:root {
  --white: #fff;
  --lightBlue: #e2eff8;
  --reallyLightBlue: #f5fafd;
  --waterBlue: #152b81;
  --skyBlue: #5a9cd7;
  --lightGreen: #01ad01;
  --landGreen: #009100;
  --grey: #c3c3c3;
  --lightGrey: #f9f9f9;
  --darkGrey: #898989;
  --grey2: #e1e1e1;
  --grey3: #5f666c;
  --kingsOrange: #e85300;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.5rem;
  line-height: 2.2rem;
  font-family: RTLGraphik, Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 0;
}

button {
  font-size: 1.5rem;
  line-height: 2.2rem;
  font-family: RTLGraphik, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: var(--landGreen);
}

.is-locked {
  @media (min-width: 320px) {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  @media (max-width: 1023px) {
    overflow: hidden;
  }
}

.hidden {
  display: none;
}

/* Credits for 'visually-hidden'
    https://www.sarasoueidan.com/blog/accessible-icon-buttons/
    https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
     */
.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
