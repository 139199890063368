@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.footer {
  background-color: var(--waterBlue);
  color: var(--white);
  padding-bottom: 32px;

  img {
	padding: 0;
  }
}

.footerContentWrapper {
  margin: 0 auto;
  max-width: 1240px;
  padding: 20px;
  display: grid;
  gap: 0px 0px;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 0.2fr;
  grid-template-areas:
    'About'
    'Other';

	@media #{map.get(v.$media, "tablet")} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'About FavoriteLocations Other';
  }

  @media #{map.get(v.$media, "desktopM")} {
    padding-left: 2%;
    padding-top: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'Quicklinks About FavoriteLocations Other';
  }
}

.About {
  grid-area: About;
}

.Quicklinks {
  grid-area: Quicklinks;

  @media #{map.get(v.$media, "desktopMMax")} {
    display: none;
  }
}

.Other {
  grid-area: Other;

  @media #{map.get(v.$media, "tabletMax")} {
    margin-top: 16px;
  }
}

.FavoriteLocations {
  grid-area: FavoriteLocations;
  @media #{map.get(v.$media, "tabletMax")} {
    display: none;
  }
}

.Copyright {
  margin: 0 auto;
  padding: 25px 2%;
  font-size: 15px;
  line-height: 17px;
  max-width: 1240px;
  color: var(--waterBlue);
  display: flex;
  justify-content: space-between;
}

.ImageContainer {
  margin-right: 10px;
}

.SocialLink {
  display: flex;
  color: var(--white);
  font-size: 14px;
  text-decoration: none;
  margin-right: 10px;
  margin-bottom: 6px;

  &[data-app-link] {
    display: none;
  }

  @media #{map.get(v.$media, "tablet")} {
    &[data-app-link] {
      display: flex;
    }
  }
}

.footerListWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-template-rows: repeat(4, 1fr);
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);

  @media #{map.get(v.$media, "tablet")} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
    border: none;
  }
}

.footerList {
  margin: 0;
  padding: 0;
}

.listItem {
  list-style-type: none;
}
