.ButtonStyle {
	background: var(--white);
	color: var(--lightGreen);
	border: 1px solid var(--lightGreen);
	cursor: pointer;
	display: inline-block;
	width: 100%;
	height: 45px;
	padding: 15px 0;
	font-size: var(--fontSize, 1.8rem);
	line-height: 1.6rem;
	font-family: RTLGraphik;
	font-weight: 500;
	text-align: center;
	border-radius: 6px;

	&:hover {
		background: var(--white);
		color: var(--landGreen);
		border: 1px solid var(--landGreen);
	}
}

.Primary {
	background: var(--lightGreen);
	color: var(--white);
	border: none;

	&:hover {
		color: var(--white);
		background: var(--landGreen);
		border: none;
	}
}
